<template>
  <div class="p-grid card" v-if="!started">
    <p-progress-spinner />
  </div>
  <div class="p-grid card" v-else>
    <h5 class="p-d-flex p-ai-center" style="width: 100%">
      <span style="flex: 1">
        Loja de Produtos
        <p-progress-spinner v-if="loading" style="width: 15px; height: 15px" />
      </span>
      <p-button label="Ver Carrinho" @click="$router.push({ name: 'cart' })" />
    </h5>

    <x-ponto-apoio-compra />

    <x-mensagem-ativacao />

    <DataView
      :value="dataviewValue"
      layout="list"
      lazy
      :paginator="true"
      :rows="dataParams.rows"
      :sortOrder="dataParams.sortOrder"
      :sortField="dataParams.sortField"
      :totalRecords="dataParams.totalRecords"
      @page="onPage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 200, 500]"
      currentPageReportTemplate="{first} - {last} de {totalRecords}"
      style="width: 100%"
    >
      <template #header>
        <div class="p-grid p-nogutter">
          <div class="p-col-12 p-sm-6 p-text-center p-text-sm-left">
            <p-dropdown
              v-model="dataParams.categoria"
              required
              :options="categorias"
              optionLabel="nome"
              placeholder="Todas as Categorias"
              dataKey="id"
              showClear
              style="min-width: 200px"
              @change="find"
              class="p-mr-2 p-mb-2"
            />
            <span class="p-d-inline-flex">
              <p-inputtext
                v-model="dataParams.filter"
                @keypress.enter="find"
                placeholder="Pesquisar..."
              />
              <p-button icon="pi pi-search" @click="find" />
            </span>
          </div>
          <div
            class="
              cart-totais
              p-col-12 p-sm-6 p-text-center p-text-md-right p-mt-2 p-mt-sm-0
            "
          >
            Total carrinho:
            {{ $formatCurrency($store.state.carrinhoTotais.total) }}
            <br />
            <span v-if="!$store.getters.isCompraPa">
              Pontuação total:
              {{ $formatNumber($store.state.carrinhoTotais.totalPontos) }}
            </span>
          </div>
        </div>
      </template>

      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">
          Nenhum produto encontrado!
        </div>
      </template>

      <template #list="{ data }">
        <div class="p-col-12">
          <x-item-card
            :item="data"
            @add="(qtd) => add(data, qtd)"
            @show="itemView.show(data.id)"
          />
        </div>
      </template>
    </DataView>
  </div>

  <x-item-view
    v-model:visible="itemView.visible"
    :title="itemView.title"
    :id="itemView.id"
  ></x-item-view>
</template>

<script lang="ts">
import { getInstance, Item } from "@/services/ItemService";
import { getInstance as getCarrinhoService } from "@/services/CarrinhoService";
import {
  getInstance as getCategoriaSerive,
  Categoria,
} from "@/services/CategoriaService";
import { reactive, ref } from "@vue/reactivity";
import { defineComponent, onMounted, watch } from "@vue/runtime-core";
import { useShowItem } from "@/components/ItemView.vue";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/actions";
import { useRouter } from "vue-router";

export default defineComponent({
  data() {
    return {
      dataviewValue: [],
      loading: false,

      dataParams: {
        rows: 25,
        page: 1,
        sortField: "e.id",
        sortOrder: -1,
        totalRecords: 0,
        filter: "",
        categoria: null,
      },

      adding: false,
    };
  },
  setup() {
    const started = ref(false);

    const store = useStore();
    const router = useRouter();

    const categorias = ref<Categoria[]>([]);
    onMounted(async () => {
      await store.dispatch(ActionTypes.LOAD_ME);
      if (!store.state.user?.perfil && !store.state.novoPerfil) {
        router.push({ name: "change-perfil" });
        return;
      }

      const { data } = await getCategoriaSerive().consultar({
        first: 100,
        page: 1,
        sortField: "nome",
        sortOrder: "ASC",
        somenteAtivas: true,
      });
      categorias.value = data;

      started.value = true;
    });

    const itemView = useShowItem();

    return {
      started,
      itemView,
      categorias,
    };
  },
  mounted() {
    this.find();
  },
  methods: {
    getUrlImagem(item: Item) {
      return (
        getInstance().getUrlImagem(item) ||
        require("@/assets/shop-placeholder.png")
      );
    },
    async find() {
      this.loading = true;
      try {
        const result = await getInstance().consultar({
          q: this.dataParams.filter,
          first: this.dataParams.rows,
          page: this.dataParams.page,
          sortField: this.dataParams.sortField,
          sortOrder: this.dataParams.sortOrder > 0 ? "ASC" : "DESC",
          somenteAtivos: true,
          disponiveisPrimeiro: true,
          categorias: this.dataParams.categoria
            ? // @ts-ignore
              [parseInt(`${this.dataParams.categoria.id}`)]
            : null!,
        });
        // @ts-ignore
        this.dataviewValue = result.data;
        this.dataParams.totalRecords = result.paginatorInfo.total;
      } finally {
        this.loading = false;
      }
    },
    onPage(event: any) {
      this.dataParams.rows = event.rows;
      this.dataParams.page = event.page + 1;
      this.find();
    },
    async add(item: Item, qtd: number) {
      this.adding = true;
      try {
        await getCarrinhoService().addItem({
          itemId: item.id,
          qtd,
        });
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Item adicionado",
          life: 3000,
        });
      } finally {
        this.adding = false;
      }
    },
  },
});
</script>

<style scoped>
.cart-totais {
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1.5em;
}
</style>