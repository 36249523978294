
import { getInstance, Item } from "@/services/ItemService";
import { getInstance as getCarrinhoService } from "@/services/CarrinhoService";
import {
  getInstance as getCategoriaSerive,
  Categoria,
} from "@/services/CategoriaService";
import { reactive, ref } from "@vue/reactivity";
import { defineComponent, onMounted, watch } from "@vue/runtime-core";
import { useShowItem } from "@/components/ItemView.vue";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/actions";
import { useRouter } from "vue-router";

export default defineComponent({
  data() {
    return {
      dataviewValue: [],
      loading: false,

      dataParams: {
        rows: 25,
        page: 1,
        sortField: "e.id",
        sortOrder: -1,
        totalRecords: 0,
        filter: "",
        categoria: null,
      },

      adding: false,
    };
  },
  setup() {
    const started = ref(false);

    const store = useStore();
    const router = useRouter();

    const categorias = ref<Categoria[]>([]);
    onMounted(async () => {
      await store.dispatch(ActionTypes.LOAD_ME);
      if (!store.state.user?.perfil && !store.state.novoPerfil) {
        router.push({ name: "change-perfil" });
        return;
      }

      const { data } = await getCategoriaSerive().consultar({
        first: 100,
        page: 1,
        sortField: "nome",
        sortOrder: "ASC",
        somenteAtivas: true,
      });
      categorias.value = data;

      started.value = true;
    });

    const itemView = useShowItem();

    return {
      started,
      itemView,
      categorias,
    };
  },
  mounted() {
    this.find();
  },
  methods: {
    getUrlImagem(item: Item) {
      return (
        getInstance().getUrlImagem(item) ||
        require("@/assets/shop-placeholder.png")
      );
    },
    async find() {
      this.loading = true;
      try {
        const result = await getInstance().consultar({
          q: this.dataParams.filter,
          first: this.dataParams.rows,
          page: this.dataParams.page,
          sortField: this.dataParams.sortField,
          sortOrder: this.dataParams.sortOrder > 0 ? "ASC" : "DESC",
          somenteAtivos: true,
          disponiveisPrimeiro: true,
          categorias: this.dataParams.categoria
            ? // @ts-ignore
              [parseInt(`${this.dataParams.categoria.id}`)]
            : null!,
        });
        // @ts-ignore
        this.dataviewValue = result.data;
        this.dataParams.totalRecords = result.paginatorInfo.total;
      } finally {
        this.loading = false;
      }
    },
    onPage(event: any) {
      this.dataParams.rows = event.rows;
      this.dataParams.page = event.page + 1;
      this.find();
    },
    async add(item: Item, qtd: number) {
      this.adding = true;
      try {
        await getCarrinhoService().addItem({
          itemId: item.id,
          qtd,
        });
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Item adicionado",
          life: 3000,
        });
      } finally {
        this.adding = false;
      }
    },
  },
});
